import React from "react";
import '../../styles/semantic.min.css';
import SEO from "../../components/seo/Seo";
import LayoutContainer from "../../container/LayoutContainer";
import PageHeader from "../../components/utils/PageHeader";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import {Container, Icon} from "semantic-ui-react";
import layout from "../../templates/layout.module.css";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";

export default () => <LayoutContainer>
    <SEO title={'Danke'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container fluid>
            <Icon name={'check circle'} size={'massive'} color={'green'}
                  style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
            <PageHeader title={'Vielen Dank'}/>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <Icon name={'check circle'} size={'massive'} color={'green'}
                  style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
            <PageHeader title={'Vielen Dank'}/>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>