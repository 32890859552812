import React from "react";
import styles from "./pageheader.module.css";
import {Header} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";

const PageHeader = ({title, subHeader}) => (
    <>
        <MobileTabletContainer className={styles.mobile}>
            <Header className={styles.main} as={'h1'}>
                {title}
                {
                    !!subHeader &&
                    <Header.Subheader>{subHeader}</Header.Subheader>
                }
            </Header>
        </MobileTabletContainer>
        <DesktopContainer className={styles.desktop}>
            <Header className={styles.main} as={'h1'}>
                {title}
                {
                    !!subHeader &&
                    <Header.Subheader>{subHeader}</Header.Subheader>
                }
            </Header>
        </DesktopContainer>
    </>

);

export default PageHeader